@font-face {
    font-family: "San Francisco";
    src: url('../fonts/SF-Pro-Text-Regular.otf') format('opentype');
}

body {
    font-family: 'San Francisco', sans-serif;
}

.desktop-layout {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 100;
    position: static;
    display: flex;
    flex-direction: column;
}

.desktop-film-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.desktop-film {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
}

.desktop-top-layout {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 100;
}

.desktop-bottom-layout {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 68px 40px 40px 40px;
    z-index: 1;
    position: absolute;
}

.desktop-elements {
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}

.desktop-folders {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.desktop-images {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 0px 40px;
}

.desktop-top-layout::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
}

.wino-logo {
    width: 90px;
    margin: 5px 10px 5px 20px;
    z-index: 200;
    cursor: pointer;
    display: block;
    position: relative;
}

.top-bar-layout {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    color: white;
}
  
.top-bar-left {
    display: flex;
    align-items: center;
    flex-grow: 1; 
    color: white;
}

.top-bar-logo {
    width: 60px;
    margin: 5px 10px 5px 20px;
    z-index: 2;
    cursor: pointer;
}

.top-bar-label {
    font-size: 0.8rem;
    font-weight: 600;
    display: inline-block;
    position: relative;
    padding: 2px 8px;
    text-align: center;
    color: white;
    filter: drop-shadow(-2px 2px 3px rgb(0 0 0 / 0.15));
    cursor: pointer;
}

.top-bar-left-item {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-bar-left-element {
    font-size: 0.8rem;
    font-weight: 600;
    display: inline-block;
    position: relative;
    padding: 4px 8px;
    text-align: center;
    color: white;
    filter: drop-shadow(-2px 2px 3px rgb(0 0 0 / 0.15));
    cursor: pointer;
}

.top-bar-left-element:hover {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.4);
}

.top-bar-contact-dropdown {
    position: absolute;
    width: 200px;
    border-radius: 4px;
    min-width: 200px;
    top: 28px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.781);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 1000;
}

.top-bar-social-dropdown {
    position: absolute;
    width: 140px;
    border-radius: 4px;
    min-width: 140px;
    top: 28px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: -1px 1px 4px 1px rgba(0,0,0,0.2);
    background-color: rgba(255, 255, 255, 0.781);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 1000;
}

.top-bar-dropdown-item {
    width: 90%;
    margin: 5px;
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    text-align: left;
    color: rgb(37, 37, 37);
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.top-bar-dropdown-item:hover {
    border-radius: 4px;
    color: white;
    background-color: #5998f7;
}

.top-bar-dropdown-social-item-1 {
    width: 85%;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 8px;
    margin-bottom: 1px;
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    text-align: left;
    color: rgb(37, 37, 37);
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.top-bar-dropdown-social-item-1:hover {
    border-radius: 4px;
    color: white;
    background-color: #5998f7;
}

.top-bar-dropdown-social-item-2 {
    width: 85%;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 1px;
    margin-bottom: 1px;
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    text-align: left;
    color: rgb(37, 37, 37);
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.top-bar-dropdown-social-item-2:hover {
    border-radius: 4px;
    color: white;
    background-color: #5998f7;
}

.top-bar-dropdown-social-item-3 {
    width: 85%;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 1px;
    margin-bottom: 8px;
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    text-align: left;
    color: rgb(37, 37, 37);
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.top-bar-dropdown-social-item-3:hover {
    border-radius: 4px;
    color: white;
    background-color: #5998f7;
}

.top-bar-dropdown-separator {
    width: 88%;
    margin-left: 12px;
    font-weight: 400;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: rgb(37, 37, 37);
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.top-bar-dropdown-item-a-1 {
    width: 90%;
    margin-left: 5px;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    text-align: left;
    color: rgb(37, 37, 37);
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.top-bar-dropdown-item-a-1:hover {
    border-radius: 4px;
    color: white;
    background-color: #5998f7;
}

.top-bar-dropdown-item-a-2 {
    width: 90%;
    margin-left: 5px;
    margin-bottom: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    text-align: left;
    color: rgb(37, 37, 37);
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.top-bar-dropdown-item-a-2:hover {
    border-radius: 4px;
    color: white;
    background-color: #5998f7;
}

.top-bar-right {
    display: flex;
    align-items: center;
    padding-right: 15px;
    flex-grow: 1px;
    min-width: fit-content;
    color: white;
}

.top-bar-right-item {
    font-size: 0.8rem;
    font-weight: 600;
    display: inline-block;
    min-width: fit-content;
    position: relative;
    padding: 4px 8px;
    text-align: center;
    color: white;
    filter: drop-shadow(-2px 2px 3px rgb(0 0 0 / 0.15));
    cursor: pointer;
}

.top-bar-right-item-dt {
    font-size: 0.8rem;
    font-weight: 600;
    display: inline-block;
    min-width: fit-content;
    position: relative;
    padding: 4px 8px;
    text-align: center;
    color: white;
    filter: drop-shadow(-2px 2px 3px rgb(0 0 0 / 0.15));
}

.top-bar-search-icon {
    padding-top: 3px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.top-bar-utility-button {
    display: none;
}

.top-bar-animation-button-container {
    display: flex;
}

.folder-layout {
    max-width: max-content;
    max-height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-drag {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-select: none;
}

.desktop-element-title {
    font-size: 0.85rem;
    font-weight: 600;
    display: inline-block;
    position: relative;
    padding: 2px 8px;
    text-align: center;
    color: white;
    filter: drop-shadow(-2px 2px 3px rgb(0 0 0 / 0.15));
    cursor: pointer;
}

.desktop-element-title.clicked {
    border-radius: 5px;
    background-color: #2465d8;
}

/* TextFile.js component styles below: */

.resizable-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 10vh;
    left: 20vh;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    filter: drop-shadow(-2px 2px 3px rgb(0 0 0 / 0.15));
    box-sizing: border-box;
    overflow: hidden;
    user-select: none;
}

.resizable-box .content {
    width: 100%;
}

.resizable-box .resizer {
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 5px;
    height: 5px;
    border-bottom: 2px solid rgb(131, 131, 131);
    border-right: 2px solid rgb(131, 131, 131);
    cursor: se-resize;
}

.text-file-upper-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: grab;
}

.close-button {
    cursor: pointer;
    padding: 0.5rem 0.6rem;
}

.text-file-lower-body {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    margin: 5px;
    background-color: rgb(255, 250, 250);
    overflow: scroll;
}

.upper-body-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.upper-body-content-wrapper {
    padding-right: 32px;
  }
  
  
.text-file-title {
    font-size: 1.2rem;
    font-weight: 400;
    display: inline-block;
    position: relative;
    padding: 12px 5px;
    margin-top: 2px;
    text-align: center;
    color: black;
}

.text-file-title-label {
    font-size: 1.2rem;
    font-weight: 300;
    display: inline-block;
    position: relative;
    padding: 12px 5px;
    margin-top: 2px;
    text-align: center;
    color: rgb(112, 112, 112);
}

.lower-body-content-wrapper {
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: scroll;
}

h3 {
    font-size: 1rem;
    font-weight: 500;
    display: inline-block;
    position: relative;
    text-align: center;
    color: black;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-top: 30px;
}

p {
    font-size: 0.9rem;
    font-weight: 300;
    display: inline-block;
    position: relative;
    text-align: start;
    color: black;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
}

/* StickyNote.js component styles below: */

.sticky-note-layout {
    width: 230px;
    height: 170px;
    background-color: #FEEB3D;
    border-radius: 5px;
    position: absolute;
    top: 80px;
    right: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(-2px 2px 3px rgb(0 0 0 / 0.15));
}

.sticky-note-upper {
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: grab;
}

.sticky-note-button {
    width: 3px;
    height: 3px;
    margin-left: 5px;
    margin-top: 5px;
    border: 2px solid #D9C034;
}

.sticky-note-lower {
    width: 98%;
    height: 86%;
    display: flex;
    flex-direction: column;
    background-color: #FFF6AF;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.sticky-note-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sticky-note-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
}

.sticky-note-important {
    font-weight: 600;
    margin: 5px;
    padding: 5px;
    border-radius: 100px;
}

.sticky-note-text {
    width: 100%;
    max-width: fit-content;
    height: 100%;
    font-size: 0.7rem;
    font-weight: 300;
    overflow: scroll;
    padding: 0px 10px;
}

.sticky-note-link {
    font-size: 0.7rem;
    display: inline-block;
    position: relative;
    text-align: start;
    color:#5998f7;
}

/* ContentPreviewWindow.js component styles below: */

.prev-upper-body {
    width: 100%;
    display: grid;
    grid-template-columns: 0fr 1.5fr 0.5fr;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 30px;
}

.prev-explore-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.prev-back-button {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 10px;
}

.prev-forward-button {
    padding-top: 20px;
    padding-right: 11px;
    padding-left: 10px;
}

.prev-upper-body-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: grab;
}

.prev-window-title {
    font-size: 1.4rem;
    font-weight: 300;
    display: inline-block;
    position: relative;
    padding: 12px 6px;
    margin-top: 15px;
    text-align: center;
    color: rgb(112, 112, 112);
}

.prev-window-title-label {
    font-size: 1.4rem;
    font-weight: 500;
    display: inline-block;
    position: relative;
    padding: 12px 6px;
    margin-top: 15px;
    text-align: center;
    color: black;
}

.prev-window-utility-buttons {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-right: 30px;
    cursor: grab;
}

.window-close-button {
    padding: 8px;
    margin-top: 16px;
    cursor: pointer;
}

.window-fs-button {
    padding: 8px;
    padding-right: 20px;
    margin-top: 16px;
    cursor: pointer;
}

.window-info-button {
    margin-top: 12px;
    margin-right: 24px;
    border: 1px solid rgb(112, 112, 112);
    border-radius: 5px;
    padding: 2px 5px;
    color:rgb(112, 112, 112);
    cursor: pointer;
}

.prev-lower-body-content {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.move-previous {
    height: 100%;
    width: 40%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: w-resize;
    background-color: transparent;
    z-index: 100;
}

.move-forward {
    height: 100%;
    width: 40%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: e-resize;
    background-color: transparent;
    z-index: 100;
}

.prev-window-video {
    position: relative;
    z-index: 200;
}

.prev-lower-body-image-container {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.prev-lower-body-image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
}

.prev-lower-body-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 20px;
    color: rgb(112, 112, 112);
}

/* FullscreenImage.js component styles below: */

.fullscreen-image-container {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9000;
    background-color: rgba(0, 0, 0, 0.7);
    overflow-y: auto;
}
  
.fullscreen-image-container img {
    max-width: 100%;
    width: 100%;
    height: auto;
}

.exit-fullscreen-button {
    position: fixed;
    font-size: 0.8rem;
    color:rgb(45, 45, 45);
    background-color: white;
    border-radius: 5px;
    top: 0;
    right: 0;
    padding: 7px;
    margin: 10px;
    cursor: pointer;
    z-index: 9999;
}

/* DesktopImage.js component styles below: */

.desktop-image-icon-wrapper {
    height: 72px;
}

.desktop-image-icon {
    max-width: 71px;
    max-height: 57px;
    border: 2px solid white;
    margin: 5px;
    filter: drop-shadow(-2px 2px 3px rgb(0 0 0 / 0.15));
    cursor: pointer;
}

.desktop-image-icon.clicked {
    border: 2px solid #5998f7;
}

/* SearchBar.js component styles below: */

.search-bar {
    width: 600px;
    position: absolute;
    top: 35%;
    left: 20%;
    background-color: rgba(255, 255, 255, 0.63);
    border-radius: 10px;
    padding: 0px 8px;
    border: 1px solid #c6c6c6;
    filter: drop-shadow(-8px 8px 8px rgba(0, 0, 0, 0.248));
    z-index: 9999;
}

.search-bar-icon {
    height: 20px;
    padding: 14px;
}

.input-area-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.search-bar-input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    color: rgb(49, 49, 49);
    font-size: 1.3rem;
    font-weight: lighter;
    padding-left: 0;
}

.search-bar-input::placeholder {
    color: #b3b3b3;
    font-weight: lighter;
}

.search-bar-input::-ms-input-placeholder {
    color: #b3b3b3;
    font-weight: lighter;
}

.search-bar-status-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    height: 100%;
}

.search-result-count {
    font-size: 0.8rem;
    font-weight: 400;
    color: rgb(141, 141, 141);
    padding-right: 5px;
}

.mobile-search-container {
    display: none;
}

.mobile-elements-container {
    display: none;
}

.top-bar-utility-dropdown {
    display: none;
}

.desktop-element {
    display: flex;
    align-items: center;
    justify-content: center;
}

.desktop-element-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-decoration: none;
    padding: 10px 0px;
    cursor: pointer;
}

.desktop-element-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    width: 75px;
    filter: drop-shadow(-2px 2px 3px rgba(91, 49, 49, 0.15));
}

.desktop-element-image-container.clicked {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
}

.desktop-element-image-container:hover {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
}

.desktop-element-title-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px 0px;
}

.desktop-video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 75px;
    background-color: white;
    filter: drop-shadow(-2px 2px 3px rgb(0 0 0 / 0.15));
    user-select: none;
    cursor: pointer;
}

.desktop-video-container.clicked {
    background-color: #5998f7;
}

.desktop-video-icon {
    max-width: 71px;
    max-height: 71px;
    border: 2px solid white;
    cursor: pointer;
    position: absolute;
    z-index: 50;
    transition: opacity 2s ease-in-out;
}

.desktop-video-icon.clicked {
    border: 2px solid #5998f7;
}

.desktop-video-image-container {
    display: inline;
}

.desktop-video {
    position: relative;
    z-index: 40;
}

.desktop-video-image-container-wrapper {
    height: 73px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobile-sticky-note-layout {
    display: none;
}

.react-switch-handle {
    height: 14px !important;
    width: 14px !important;
    top: 2px !important;
    left: 1px;
}

/* FinderWindow.js component styles below: */

.finder-window {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
}
  
  .sidebar {
    display: flex;
    flex-direction: column;
    width: 200px;
    background-color: #f8f8f8;
    border-right: 1px solid #ccc;
    padding: 10px;
    background-color: rgba(236, 239, 240, 0.65);
    backdrop-filter: blur(1rem) brightness(1.14);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: all 0.3s ease-in-out 0s;
    overflow: hidden;
  }

  .sidebar-title {
    font-size: 0.8rem;
    font-weight: 500;
    color: rgb(135, 140, 141);
    padding: 5px;
    margin-top: 20px;
  }

  .parent-sidebar-folders {
    flex-grow: 1;
  }

  .sidebar-folder-item-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 0.3rem;
    background-color: transparent;
  }

  .sub-sidebar-folder-item-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    padding: 5px;
    border-radius: 0.3rem;
    background-color: transparent;
  }

  .folder-content {
    display: flex;
    align-items: center;
  }
  
  .sidebar-folder-item-wrapper.selected {
    background-color: rgb(187, 206, 211);
  }

  .sub-sidebar-folder-item-wrapper.selected {
    background-color: rgb(234, 237, 237);
  }

  .parent-sidebar-contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: auto;
  }

  .parent-sidebar-contact-item {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
  }

  .parent-sidebar-contact-item-text {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .sub-sidebar {
    display: flex;
    flex-direction: column;
    width: 240px;
    background-color: #f8f8f8;
    padding: 10px;
    overflow-y: scroll;
    border-right: 0.1rem solid rgb(242, 242, 242);
    transition: border-color 0.3s ease-in-out 0s;
  }

  .sub-sidebar.hidden {
    display: none;
  }
  
  .folder-item,
  .subfolder-item {
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
  }
  
  .folder-item.selected,
  .subfolder-item.selected {
    background-color: #d8d8d8;
  }
  
  .file-item {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
   }

.file-item:hover {
  background-color: #eee;
}

.finder-right-side {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #eee;
}

.finder-right-side-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: rgb(250, 251, 251);
    border-bottom: 1px solid rgb(242, 242, 242);
    transition: all 0.3s ease-in-out 0s;
    border-top-right-radius: 0.5rem;
    cursor: grab;
}

.finder-folder-title {
    font-size: 1.4rem;
    font-weight: 500;
    display: inline-block;
    position: relative;
    padding: 5px 10px;
    text-align: center;
    color: black;
}

.finder-folder-title.disabled {
    opacity: 0.2;
}

.finder-right-side-bottom {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.main-finder-area {
    width: calc(100% - 200px);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding: 10px;
    padding-right: 30px;
    overflow-y: scroll;
}

.main-finder-area-alt {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: max-content;
    grid-gap: 10px;
    padding: 10px;
    padding-right: 30px;
    overflow-y: scroll;
}

.main-finder-image-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
    width: 100%;
    height: max-content;
    overflow: hidden;
    cursor: pointer;
}

.main-finder-image-wrapper-disabled {
    display: none;
}

.main-finder-image-overlay {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    color: white;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    filter: drop-shadow(-2px 2px 3px rgb(0 0 0 / 0.15));
}

.main-finder-image-overlay img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.main-finder-image-title {
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
    color: black;
}

.finder-video-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.finder-video-wrapper {
    opacity: 0;
    width: 100%;
    cursor: pointer;
    height: 100%;
    position: absolute;
    z-index: 50;
}

.default-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mobile-finder-window {
    display: none;
}

.music-player-container-d {
    width: 230px;
    height: 60px;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 270px;
    right: 60px;
    z-index: 50;
    border-radius: 5px;
    background-color: #ffffff;
    filter: drop-shadow(-2px 2px 3px rgb(0 0 0 / 0.15));
}

.music-player-container-m {
    display: none;
}

.music-player-wrapper {
    width: 230px;
}

.yt-thumbnail-container {
    width: 60px;
    height: 60px;
    border-radius: 5px 5px 0px 0px;
}

.yt-thumbnail-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px 0px 0px 5px;
}

.music-player-button {
    width: 8px;
    max-width: 8px;
    max-height: 8px;
    height: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
}

.music-player-button svg {
    width: 10px;
    max-width: 10px;
    max-height: 10px;
    height: 10px;
    overflow: visible;
}

.music-player-button-alt {
    width: 8px;
    max-width: 8px;
    max-height: 8px;
    height: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
}

.music-player-button-alt svg {
    width: 10px;
    max-width: 10px;
    max-height: 10px;
    height: 10px;
    overflow: visible;
}

.music-player-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2px 30px 5px 30px;
}

.player-progress-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    padding: 4px 0px;
}

.player-primary-text {
    font-size: 0.6rem;
    font-weight: 600;
    color: #000000;
    padding: 0px 6px;
    margin: 0px !important;
}

.player-secondary-text {
    font-size: 0.4rem;
    font-weight: 600;
    color: #BBBDBF;
    padding: 0px 6px;
    margin: 0px !important;
}

.music-player-meta-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 0px 1px 0px;
    cursor: grab;
}

.player-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 60px);
    height: 100%;
}

.player-wrapper {
    display: flex;
    flex-direction: row;
    height: 60px;
    width: 100%;
}

.rc-slider {
    padding: 0px 0px 2px 0px !important;
    height: unset !important;
}

.rc-slider-handle {
    visibility: hidden;
}

.rc-slider-rail {
    background-color: #E4E6E9 !important;
    height: 2px !important;
}

.rc-slider-track {
    background-color: #99D3F7 !important;
    height: 2px !important;
}

.rc-slider-disabled {
    background-color: transparent !important;
}

/* LoadingScreen.js component styles below: */

.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f3f3f3;
  }
  
  .progress-container {
    width: 50%;
    height: 1rem;
    background-color: #e0e0e0;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
  }
  
  .progress-bar {
    width: 0%;
    height: 100%;
    background-color: #1565c0;
    animation: loading-animation 2s linear infinite;
  }
  
  @keyframes loading-animation {
    0% {
      width: 0%;
    }
    50% {
      width: 100%;
    }
    100% {
      width: 100%;
    }
  }

  .ghost-box {
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .ghost-box-content {
    width: 90%;
    height: 70%;
    background-color: #e0e0e0;
    border-radius: 0.6rem;
    animation: pulse 2s infinite;
  }

  .ghost-box-title {
    width: 90%;
    height: 1rem;
    background-color: #e0e0e0;
    border-radius: 0.3rem;
    margin-top: 0.6rem;
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  .load-bg {
    background-image: url('../images/desktop-bg.webp'); /* Set the path to your desktop background image */
    background-size: cover; /* Scale the background image to cover the entire div */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent repeating the image if it's smaller than the div */
  }

  .blur-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(60px);
    z-index: -1; /* Place it behind other content */
  }
  
  .loading-bar {
    width: 240px;
    height: 8px;
    background: #99b9c4;
    border-radius: 4px;
  }
  
  .loading-bar-inside {
    width: 0%;
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
    animation: loading-animation 1s linear;
  }